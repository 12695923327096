<template>
  <RevForm :initial-values="initialValues" :validate="validate" @submit="save">
    <template #default="{ values }: any">
      <RevToggle
        :checked="true"
        class="px-0 pb-20"
        :disabled="true"
        :label="i18n(translations.functional)"
        position="sticked"
      >
        <p class="body-1">
          {{ i18n(translations.functionalDescription) }}
        </p>
        <ToggleText>
          <p class="body-1 mt-12">
            {{ i18n(translations.functionalList) }}
          </p>
        </ToggleText>
      </RevToggle>
      <RevToggle
        :checked="values.userExperience"
        class="px-0 py-20"
        :label="i18n(translations.userExperience)"
        position="sticked"
        @change="
          (event) => {
            values.userExperience = event.target.checked
          }
        "
        @click="handleToggle('userExperience')"
      >
        <span class="body-1">
          {{ i18n(translations.userExperienceDescription) }}
        </span>
        <ToggleText>
          <p class="body-1 mt-12">
            {{ i18n(translations.userExperienceList) }}
          </p>
        </ToggleText>
      </RevToggle>
      <RevToggle
        :checked="values.analytics"
        class="px-0 py-20"
        :label="i18n(translations.analytics)"
        position="sticked"
        @change="
          (event) => {
            values.analytics = event.target.checked
          }
        "
        @click="handleToggle('analytics')"
      >
        <span class="body-1">
          {{ i18n(translations.analyticsDescription) }}
        </span>
        <ToggleText>
          <p class="body-1 mt-12">
            {{ i18n(translations.analyticsList) }}
          </p>
        </ToggleText>
      </RevToggle>
      <RevToggle
        :checked="values.advertising"
        class="px-0 py-20"
        :label="i18n(translations.advertising)"
        position="sticked"
        @change="
          (event) => {
            values.advertising = event.target.checked
          }
        "
        @click="handleToggle('advertising')"
      >
        <span class="body-1">
          {{ i18n(translations.advertisingDescription) }}
        </span>
        <ToggleText>
          <p class="body-1 mt-12">
            {{ i18n(translations.advertisingList) }}
          </p>
        </ToggleText>
      </RevToggle>
      <div class="body-1">
        <p>
          {{ i18n(translations.legalText) }}
        </p>
        <br />
        <FormattedMessage :definition="translations.genericLink">
          <template #cookiesLink>
            <RevLink target="_blank" :to="LEGAL_PAGES.COOKIES">
              <span class="body-2-bold">
                {{ i18n(translations.cookiesText) }}
              </span>
            </RevLink>
          </template>
          <template #dataProtectionLink>
            <RevLink target="_blank" :to="LEGAL_PAGES.DATA_PROTECTION">
              <span class="body-2-bold">
                {{ i18n(translations.dataProtectionText) }}
              </span>
            </RevLink>
          </template>
        </FormattedMessage>
      </div>
      <div :class="{ 'md:flex md:justify-start': shrinkButtonDesktop }">
        <RevButton
          class="mx-0 my-20 md:basis-0"
          :disabled="loading"
          full-width="always"
          :loading="loading"
          type="submit"
          variant="primary"
        >
          {{ buttonLabel }}
        </RevButton>
      </div>
    </template>
  </RevForm>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'
import { RevButton } from '@ds/components/Button'
import { FORM_VALID, RevForm } from '@ds/components/Form'
import { RevLink } from '@ds/components/Link'
import { RevToggle } from '@ds/components/Toggle'

import { useLegalStore } from '@backmarket/nuxt-module-tracking/useLegalStore'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import type { Cookie } from '../../types'
import {
  COOKIES_TRACKING_NAME,
  COOKIES_TRACKING_PREFIX,
  LEGAL_PAGES,
} from '../CookiesModal/constants'

import translations from './CookiesSettings.translations'
import ToggleText from './ToggleText/ToggleText.vue'

export type CookiesSettingsProps = {
  buttonLabel: string
  shrinkButtonDesktop?: boolean
  zone: string
}

type SettingsEmit = {
  error: []
  success: []
}

const props = withDefaults(defineProps<CookiesSettingsProps>(), {
  shrinkButtonDesktop: false,
})

const emit = defineEmits<SettingsEmit>()

const logger = useLogger()
const i18n = useI18n()
const legalStore = useLegalStore()
const { trackClick, trackFormSubmit } = useTracking()

const loading = ref(false)
const initialValues = reactive(legalStore.consent)

const trackingZone = computed(() => `${COOKIES_TRACKING_PREFIX}_${props.zone}`)

function handleToggle(type: Cookie) {
  trackClick({
    zone: trackingZone.value,
    name: `${COOKIES_TRACKING_NAME.TOGGLE}_${type}`,
  })
}

async function save(values: Record<string, unknown>) {
  loading.value = true

  trackFormSubmit({
    zone: trackingZone.value,
    name: COOKIES_TRACKING_NAME.SAVE,
  })

  try {
    await legalStore.save(values)
    emit('success')
  } catch (error) {
    if (error instanceof Error) {
      logger.error('[CookiesSettings] Error saving the user choice', { error })
    }
    emit('error')
  } finally {
    loading.value = false
  }
}

// there is nothing to validate
function validate() {
  return FORM_VALID
}
</script>
